import { Component, OnInit, Input } from '@angular/core';

@Component({
  selector: 'app-billing-statement-account-summary',
  templateUrl: './billing-statement-account-summary.component.html',
  styleUrls: ['./billing-statement-account-summary.component.scss']
})
export class BillingStatementAccountSummaryComponent implements OnInit {

@Input() bill:any;
bcValue = "21315asda";
  constructor() { }

  ngOnInit() {
  	this.bcValue = this.bill.electricbillsummary.accountentry.accountbalancesummary.accountnumber;
  }

}
