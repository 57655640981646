import { Component, OnInit, Input } from '@angular/core';


@Component({
  selector: 'app-billing-statement-consumption-chart',
  templateUrl: './billing-statement-consumption-chart.component.html',
  styleUrls: ['./billing-statement-consumption-chart.component.scss']
})
export class BillingStatementConsumptionChartComponent implements OnInit {
  @Input() bill:any;
  @Input() billAdImage = {url:"", isUploading:false, isDefault:true};

  consumptions;
  average = 0;
  months = 0;
  hide = true;
  width = 2;
  height = 1;

  constructor() { }

  ngOnInit() {
    this.consumptions = this.bill.electricbillsummary.accountentry.bill_notes_list.service.bill_note_data.contractdata.consumptionhistorical;
    this.average = parseInt(this.bill.electricbillsummary.accountentry.bill_notes_list.service.bill_note_data.contractdata.consumptionhistorical.consumption1.usage);
    this.chartMapper();
  }

  
 public barChartOptions:any = {
    scaleShowVerticalLines: false,
    responsive: true
  };
  public barChartLabels:string[] = ['Au', 'Se', 'Oc', 'No', 'De', 'Ja', 'Fe', 'Ma', 'Ap', 'My', 'Jn', 'Jl'];
  private colors = [
    {
      backgroundColor: [
        
      ]
    }
  ]
  public barChartType:string = 'bar';
  public barChartLegend:boolean = false;

  public barChartData:any[] = [
    { data: [600, 600, 500, 450, 470, 480, 300, 310, 500, 1300, 1500, 760],
      label: 'Your monthly electricity consumption chart',
      backgroundColor:"#000000"}
  ];

  // events
  public chartClicked(e:any):void {
    console.log(e);
  }

  public chartHovered(e:any):void {
    console.log(e);
  }

  public randomize():void {
    // Only Change 3 values
    let data = [
      Math.round(Math.random() * 100),
      59,
      80,
      (Math.random() * 100),
      56,
      (Math.random() * 100),
      40];
    let clone = JSON.parse(JSON.stringify(this.barChartData));
    clone[0].data = data;
    this.barChartData = clone;
    /**
     * (My guess), for Angular to recognize the change in the dataset
     * it has to change the dataset variable directly,
     * so one way around it, is to clone the data, change it and then
     * assign it;
     */
  }

  monthMapper(val){
    let month = val.slice(0,val.indexOf("/"));
    let value;
    switch(month){
      case '01': value = 'Ja'; break;
      case '02': value = 'Fe'; break;
      case '03': value = 'Ma'; break;
      case '04': value = 'Ap'; break;
      case '05': value = 'My'; break;
      case '06': value = 'Jn'; break;
      case '07': value = 'Jl'; break;
      case '08': value = 'Au'; break;
      case '09': value = 'Se'; break;
      case '10': value = 'Oc'; break;
      case '11': value = 'No'; break;
      case '12': value = 'De'; break;
    }

    return value;

  }

  chartMapper(){
    let arr = [];
    for(var i = 1; i<=12; i++){
      let field = "consumption"+i+"";
      if(this.consumptions[field] != undefined){
        arr.push(this.consumptions[field]);
        this.colors[0].backgroundColor.push('rgba(0,0,0, 1)');
      }
    }
    console.log(this.average);
    this.months = arr.length;

    console.log(arr.length);
this.barChartLabels = [];
this.barChartData[0].data = [];
    arr.forEach((obj)=>{
      this.barChartLabels.push(this.monthMapper(obj.todate));
      this.barChartData[0].data.push(obj.usage);

    });

    this.hide = false;
  }
}
