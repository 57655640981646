import { Component, OnInit, AfterViewInit, Input } from '@angular/core';
import { PdfGeneratorService } from '../../../../services/pdf-generator.service';

@Component({
  selector: 'app-page-one',
  templateUrl: './page-one.component.html',
  styleUrls: ['./page-one.component.scss']
})
export class PageOneComponent implements OnInit {

	@Input() bill:any;
  @Input() billAdImage = {url:"", isUploading:false, isDefault:true};
  
  constructor(public pdfGeneratorService: PdfGeneratorService) { }

  ngOnInit() {
    let bill_note_data = this.bill.electricbillsummary.accountentry.bill_notes_list.service.bill_note_data
    if(Array.isArray(bill_note_data)){
      this.bill.electricbillsummary.accountentry.bill_notes_list.service.bill_note_data = bill_note_data[0]
    }
  }

  ngAfterViewInit(){
  //this.pdfGeneratorService.generate('Billing Statement');
  }
}
