import { Component, OnInit, Input } from '@angular/core';

@Component({
  selector: 'app-billing-statement-reference-section-two',
  templateUrl: './billing-statement-reference-section-two.component.html',
  styleUrls: ['./billing-statement-reference-section-two.component.scss']
})
export class BillingStatementReferenceSectionTwoComponent implements OnInit {

	@Input()bill:any;
	bcValue= '';
	@Input()
	referenceContent = {}

  constructor() { }

  ngOnInit() {
  	// let statementNumber = this.bill.electricbillsummary.accountentry.bill_notes_list.service.invoicesummary.statementnumber;
  	// let sin = this.bill.electricbillsummary.accountentry.bill_notes_list.service.sin;
  	// let strippedInvoiceDueDate = this.bill.electricbillsummary.accountentry.bill_notes_list.service.invoicesummary.invoiceduedate.replace("/","");
  	// let paddedLength = statementNumber.length;
  	// let padded = "00000000000000".slice(0,paddedLength-14);
  	// let paddedStatementNumber = padded+statementNumber;

  	// this.bcValue = statementNumber+sin+strippedInvoiceDueDate;
  }

}
