import {Component, OnInit, ViewChild, Input, Output, EventEmitter, OnChanges, SimpleChanges, SimpleChange, ChangeDetectorRef} from '@angular/core';
import {MatPaginator, MatTableDataSource} from '@angular/material';
import {SelectionModel} from '@angular/cdk/collections';
import { DataRowOutlet } from '@angular/cdk/table';
export interface PeriodicElement {
  name: string;
  position: number;
  weight: number;
  symbol: string;
}




@Component({
  selector: 'app-loa-table',
  templateUrl: './loa-table.component.html',
  styleUrls: ['./loa-table.component.scss']
})
export class LoaTableComponent implements OnInit {
 	 displayedColumns: string[] = ['doPrint'];
 	 
 	@Output()
  rowEvent = new EventEmitter<any>();

  constructor(private cdr: ChangeDetectorRef) { }
  
 	 @Input() data:any[];
 	 @Input() dataColumns: any[];
 	 @Input() headers:any[];
 	 @Input() doPrint:boolean = false;

  	dataSource = null;
 	selection = new SelectionModel<PeriodicElement>(true, []);
  	@ViewChild(MatPaginator) paginator: MatPaginator;

  	ngOnInit() {
    	
    	this.dataSource = new MatTableDataSource<any>(this.data);
    	this.dataSource.paginator = this.paginator;
      this.shouldAppendDoPrintColumn();
  	}

  /** Whether the number of selected elements matches the total number of rows. */
  isAllSelected() {
    const numSelected = this.selection.selected.length;
    const numRows = this.dataSource.data.length;
    return numSelected > 0;
  }

  ngOnChanges(changes: SimpleChanges) {
    const data: SimpleChange = changes.data;
    console.log('prev value: ', data.previousValue);
    console.log('current value: ', data.currentValue);
    this.dataSource = new MatTableDataSource<any>(data.currentValue);
  }

  /** Selects all rows if they are not all selected; otherwise clear selection. */
  masterToggle() {
    this.isAllSelected() ?
        this.selection.clear() :
        this.dataSource.data.forEach(row => this.selection.select(row));
        this.cdr.detectChanges();
  }


  shouldAppendDoPrintColumn(){
  	if(this.doPrint == true){
  		[].push.apply(this.displayedColumns,this.dataColumns);
  	}else{
  		this.displayedColumns = this.dataColumns;
  	}
  }

  clickRow(row){
     this.rowEvent.emit(row);
  }

}
