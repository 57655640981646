import { Component, OnInit } from '@angular/core';

@Component({
  selector: 'app-billing-statement-header-page-three',
  templateUrl: './billing-statement-header-page-three.component.html',
  styleUrls: ['./billing-statement-header-page-three.component.scss']
})
export class BillingStatementHeaderPageThreeComponent implements OnInit {

  constructor() { }

  ngOnInit() {
  }

}
