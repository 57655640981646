import { Component, OnInit, Input } from '@angular/core';
import { ElementRef, ViewChild } from '@angular/core';
import { ajax } from 'rxjs/ajax';

@Component({
  selector: 'app-back-parent',
  templateUrl: './back-parent.component.html',
  styleUrls: ['./back-parent.component.scss']
})
export class BackParentComponent implements OnInit {

  // list of varables
    @Input() bill:any;  // json content of a bill
    @Input() pageView:any;   // Type of pageview
    totalHeightLeft  = 44;   // (6 is on top) total height of billing details (per lineper line)
    totalHeightRight = 39;   // total height of billing details (per line)

    // meteringInfo
    displayKWH              = false;
    meteringInfoList        = [];
    counterMeteringInfoList = 0;

    // referenceSectionTwo
    referenceSectionTwoList = {};

    // billingDetailsParser
    subheaders                  = [];
    rightBillingDetails         = [];
    leftBillingDetails          = [];
    rightCounterBillingDetails  = 0;
    leftCounterBillingDetails   = 0;
    thirdCounterBillingDetails  = 0;

    // energyBillAmountList
    rightEnergyBillAmountList = [];
    thirdEnergyBillAmountList = [];

    // nmExport
    rightNmExportList = [];
    thirdNmExportList = [];

    // otherCharges
    rightOtherChargesList = [];
    thirdOtherChargesList = [];

    // appliedCredits
    rightAppliedCreditsList = [];
    thirdAppliedCreditsList = [];

    // totalBill
    rightTotalBillList = [];
    thirdTotalBillList = [];

    // additionalBillInformation
    rightAdditionalBillInformation = [];
    thirdAdditionalBillInformation = [];

    // additionalBillInformation
    rightAdditionalAccountInformation = [];
    thirdAdditionalAccountInformation = [];

    // check if bill should contain 3rd page
    thirdPage = false;

  constructor() { }

  ngOnInit() {
    this.referenceSectionTwo(this.bill)
    this.meteringInfo(this.bill)
    this.billingDetailsParser(this.bill)
    this.energyBillAmount(this.bill)
    this.nmExport(this.bill)
    this.otherCharges(this.bill)
    this.appliedCredits(this.bill)
    this.totalBill(this.bill)
    this.additionalBillInformation(this.bill)
    this.additionalAccountInformation(this.bill)
    this.thirdPage = this.hasThird()
  }

  public referenceSectionTwo(content){
    let bill_note_data: any;
    let billNumber: any;
    let contractholder: any;
    let statementNumber: any;
    let sin: any;
    let billingPeriodFrom: any;
    let billingPeriodTo: any;
    let invoiceNumber: any;
    
    bill_note_data = content.electricbillsummary.accountentry.bill_notes_list.service.bill_note_data
    if(Array.isArray(bill_note_data)){
      bill_note_data = bill_note_data[0]
    }

    billNumber        = bill_note_data.billnotedata.billnumber;
    contractholder    = content.electricbillsummary.accountentry.bill_notes_list.service.contractholder;
    statementNumber   = content.electricbillsummary.accountentry.bill_notes_list.service.invoicesummary.statementnumber;
    sin               = content.electricbillsummary.accountentry.bill_notes_list.service.sin;
    billingPeriodFrom = bill_note_data.billnotedata.billingperiodfrom;
    billingPeriodTo   = bill_note_data.billnotedata.billingperiodto;
    invoiceNumber     = content.electricbillsummary.accountentry.bill_notes_list.service.invoicesummary.invoicenumber;
    billingPeriodFrom = new Date(billingPeriodFrom);
    billingPeriodTo   = new Date(billingPeriodTo);

    this.referenceSectionTwoList = {
        "billNumber":billNumber,
        "contractholder":contractholder,
        "statementNumber":statementNumber,
        "sin":sin,
        "billingPeriodFrom":billingPeriodFrom,
        "billingPeriodTo":billingPeriodTo,
        "invoiceNumber":invoiceNumber,
      }
  }

  public meteringInfo(content) {
    let bill_note_data: any;
    let rate: any;
    let readingList: any;

    bill_note_data = content.electricbillsummary.accountentry.bill_notes_list.service.bill_note_data
    if(Array.isArray(bill_note_data)){
      bill_note_data = bill_note_data[0]
    }
    // find rateName
    rate = bill_note_data.contractdata.ratelist.rate.ratename;

    // get inddisplaypriceratekwh
    // this.displayKWH = bill_note_data.contractdata.inddisplaypriceratekwh;

    // if flat streetlight remove the whole metering
    if (rate == "Flat Streetlights" || rate == "Flat Streetlight"){
      this.displayKWH = true
    }

    readingList = bill_note_data.contractdata.readingslist.reading;

    if(!Array.isArray(readingList)){
      this.meteringInfoList.push(
        {
          "meternumber"         : readingList.meternumber,
          "usagetype"           : readingList.usagetype,
          "initialreadingvalue" : readingList.initialreadingvalue,
          "endreadingvalue"     : readingList.endreadingvalue,
          "usage"               : readingList.usage,
          "usageunits"          : readingList.usageunits,
          "factor"              : readingList.factor,
          "rateperkwh"          : readingList.rateperkwh,
        }
      )
      this.counterMeteringInfoList += 1;
    }
    else{
      readingList.forEach(reading => {
        if(reading.meternumber != "null"){
          this.meteringInfoList.push(
            {
              "meternumber"         : reading.meternumber,
              "usagetype"           : reading.usagetype,
              "initialreadingvalue" : reading.initialreadingvalue,
              "endreadingvalue"     : reading.endreadingvalue,
              "usage"               : reading.usage,
              "usageunits"          : reading.usageunits,
              "factor"              : reading.factor,
              "rateperkwh"          : reading.rateperkwh,
            }
          )
          this.counterMeteringInfoList += 1;
        }
      })
    }
    // for end line add 1 and 2 header below and 3 aboive
    this.counterMeteringInfoList += 6;
  }

  public billingDetailsParser(content) {
    let meralclist: any;
    let hField: any;
    let toRemove;
    let splitText;
    let bill_note_data = content.electricbillsummary.accountentry.bill_notes_list.service.bill_note_data;
    let informations   = bill_note_data.messagesdata.additionalinformationlist.addinfo;
    let counter = 0;
    if(Array.isArray(bill_note_data)){
      bill_note_data = bill_note_data[0]
    }

    if(informations){
      if(!Array.isArray(informations)){
        informations = [informations]
      }
    }

    if(informations[informations.length - 1].information){
      splitText = informations[informations.length - 1].information.split(",");
      toRemove = splitText[0]
    }
    console.log("toRemove")
    console.log(toRemove)

    if(Array.isArray(bill_note_data)){
      bill_note_data = bill_note_data[0]
    }

    // add count of the table
    this.leftCounterBillingDetails = this.counterMeteringInfoList;

    // parse up to the element list
    meralclist = bill_note_data.contractdata.meralcoconceptslist.concept;
    hField = bill_note_data.contractdata.billingsummary.billgrouplist.billgroup;

    // loop the header
    hField.forEach(y => {
      if(y.headerfield != "Other Charges"){

        // START """add the header to the array Content"""
        if (this.leftCounterBillingDetails < this.totalHeightLeft){
          this.leftBillingDetails.push({"header":y.headerfield})
          this.leftCounterBillingDetails += 1;
        }
        else{
          this.rightBillingDetails.push({"header":y.headerfield})
          this.rightCounterBillingDetails += 1;
        }
        // END """add the header to the array Content"""



        // START"""loop the content
        meralclist.forEach(x => {
          // if the header of meralclist and hField is equal to Content
          // add the header to the array Content
          
          if (y.headerfield==x.headerfield){
            if (this.leftCounterBillingDetails < this.totalHeightLeft){
              
              if (!this.subheaders.includes(x.subheaderfield) && (x.subheaderfield != "null")){
                this.subheaders.push(x.subheaderfield);
                this.leftBillingDetails.push({"subheaderAdded":x.subheaderfield});
                this.leftBillingDetails.push(x);
                this.leftCounterBillingDetails += 2;
              }
              else{

                if(x.unitcalculationbase == "kWh"){
                  x.calculationbase = String(Number(x.calculationbase))
                }
                
                if(!(x.conceptname.indexOf(toRemove) >= 0)){
                  if(x.conceptname == " Transmission Charge (NONVAT)"){
                    x.amountconcept = "null"
                    this.leftBillingDetails.push(x)
                    this.leftCounterBillingDetails += 1;
                    
                  }
                  else if (x.conceptname == " Transmission Charge (VAT)"){
                    x.amountconcept = "null"
                    this.leftBillingDetails.push(x)
                    this.leftCounterBillingDetails += 1;
                    
                  }
                  else{
                    this.leftBillingDetails.push(x)
                    this.leftCounterBillingDetails += 1;
                  }
                  
                }
              }
            }

            else{
              if (!this.subheaders.includes(x.subheaderfield) && (x.subheaderfield != "null")){
                this.subheaders.push(x.subheaderfield)
                this.rightBillingDetails.push({"subheaderAdded":x.subheaderfield})
                this.rightBillingDetails.push(x)
                this.rightCounterBillingDetails += 2;
              }
              else{
                if(!(x.conceptname.indexOf(toRemove) >= 0)){
                  if(x.conceptname == " Transmission Charge (NONVAT)"){
                    x.amountconcept = "null"
                    this.rightBillingDetails.push(x)
                    this.rightCounterBillingDetails += 1;

                  }
                  else if (x.conceptname == " Transmission Charge (VAT)"){
                    x.amountconcept = "null"
                    this.rightBillingDetails.push(x)
                    this.rightCounterBillingDetails += 1;

                  }
                  else{
                    this.rightBillingDetails.push(x)
                    this.rightCounterBillingDetails += 1;
                  }
                }
              }
            }
          }
        });
        // END"""loop the content


        // START"""add the Subtotal to the array Content"""
        if (this.leftCounterBillingDetails < this.totalHeightLeft){
          this.leftBillingDetails.push( {"Subtotal":y.totalamount})
          this.leftCounterBillingDetails += 1;
        }
        else{
          this.rightBillingDetails.push( {"Subtotal":y.totalamount})
          this.rightCounterBillingDetails += 1;
        }
        // END"""add the Subtotal to the array Content"""



        // START"""add the whiteSpace to the array Content"""
        if (this.leftCounterBillingDetails < this.totalHeightLeft){
          this.leftBillingDetails.push({"Space":"br"})
          this.leftCounterBillingDetails += 1;
        }
        else{
          this.rightBillingDetails.push({"Space":"br"})
          this.rightCounterBillingDetails += 1;
        }
        // END"""add the whiteSpace to the array Content"""
      }
    });

    // IF RIGHT HAS CONTENT ADD ADDITIONAL SPACE START AND END
    if (this.rightCounterBillingDetails != 0){
      this.rightCounterBillingDetails += 2;
    }
    console.log("this.rightBillingDetails")

    console.log(this.rightBillingDetails)
  }

  public energyBillAmount(content){
    let energyvatbase: any;
    let energyvat: any;
    let energyzerorated: any;
    let energyvatexempt: any;
    let invoiceamount: any;

    if (content.electricbillsummary.accountentry.bill_notes_list.service.invoicesummary){
      energyvatbase = content.electricbillsummary.accountentry.bill_notes_list.service.invoicesummary.energyvatbase;      // VAT Sales = Base
      energyvat = content.electricbillsummary.accountentry.bill_notes_list.service.invoicesummary.energyvat;              // VAT Sales = VAT
      energyzerorated = content.electricbillsummary.accountentry.bill_notes_list.service.invoicesummary.energyzerorated;  // VAT Zero Rated = Base
      energyvatexempt = content.electricbillsummary.accountentry.bill_notes_list.service.invoicesummary.energyvatexempt;  // Non-VAT = Base
      invoiceamount = content.electricbillsummary.accountentry.bill_notes_list.service.invoicesummary.invoiceamount;      // Total energy amount

      // add the header to the array Content
      if (this.rightCounterBillingDetails < this.totalHeightRight){
        this.rightEnergyBillAmountList.push({"header":"Energy Bill Amount"})
        this.rightCounterBillingDetails += 1;
      }
      else{
        this.thirdEnergyBillAmountList.push({"header":"Energy Bill Amount"})
        this.thirdCounterBillingDetails += 1;
      }

      // add the subheader to the array Content
      if (this.rightCounterBillingDetails < this.totalHeightRight){
        this.rightEnergyBillAmountList.push({"subheader1":"Base","subheader2":"VAT","subheader3":"null"})
        this.rightCounterBillingDetails += 1;
      }
      else{
        this.thirdEnergyBillAmountList.push({"subheader1":"Base","subheader2":"VAT","subheader3":"null"})
        this.thirdCounterBillingDetails += 1;
      }

      if (this.rightCounterBillingDetails < this.totalHeightRight){
        this.rightEnergyBillAmountList.push({"title":"VAT Sales","content1":energyvatbase,"content2":energyvat,"content3":"null"})
        this.rightCounterBillingDetails += 1;
      }
      else{
        this.thirdEnergyBillAmountList.push({"title":"VAT Sales","content1":energyvatbase,"content2":energyvat,"content3":"null"})
        this.thirdCounterBillingDetails += 1;
      }

      if (this.rightCounterBillingDetails < this.totalHeightRight){
        this.rightEnergyBillAmountList.push({"title":"VAT Zero Rated","content1":energyzerorated,"content2":"null","content3":"null"})
        this.rightCounterBillingDetails += 1;
      }
      else{
        this.thirdEnergyBillAmountList.push({"title":"VAT Zero Rated","content1":energyzerorated,"content2":"null","content3":"null"})
        this.thirdCounterBillingDetails += 1;
      }

      if (this.rightCounterBillingDetails < this.totalHeightRight){
        this.rightEnergyBillAmountList.push({"title":"Non-VAT","content1":energyvatexempt,"content2":"null","content3":"null"})
        this.rightCounterBillingDetails += 1;
      }
      else{
        this.thirdEnergyBillAmountList.push({"title":"Non-VAT","content1":energyvatexempt,"content2":"null","content3":"null"})
        this.thirdCounterBillingDetails += 1;
      }

      // TOTAL
      if (this.rightCounterBillingDetails < this.totalHeightRight){
        this.rightEnergyBillAmountList.push({"total":"TOTAL ENERGY AMOUNT","content1":invoiceamount})
        this.rightCounterBillingDetails += 1;
      }
      else{
        this.thirdEnergyBillAmountList.push({"total":"TOTAL ENERGY AMOUNT","content1":invoiceamount})
        this.thirdCounterBillingDetails += 1;
      }
    }
  }

  public nmExport(content){
    let bill_note_data: any;
    let meralclist: any;
    let stop: any;
    let base: any;

    bill_note_data = content.electricbillsummary.accountentry.bill_notes_list.service.bill_note_data
    if(Array.isArray(bill_note_data)){
      bill_note_data = bill_note_data[0]
    }

    meralclist = bill_note_data.contractdata.meralcoconceptslist.concept;
    stop = false
    meralclist.forEach(x => {
      if ((x.headerfield == "Net Metering Export Energy") && (stop == false)){
        // TITLE
        // add the header to the array Content
        if (this.rightCounterBillingDetails < this.totalHeightRight){
          this.rightEnergyBillAmountList.push({"header":x.headerfield})
          this.rightCounterBillingDetails += 1;
        }
        else{
          this.thirdEnergyBillAmountList.push({"header":x.headerfield})
          this.thirdCounterBillingDetails += 1;
        }

        // add the subheader to the array Content
        if (this.rightCounterBillingDetails < this.totalHeightRight){
          this.rightNmExportList.push({"subheader1":"Base","subheader2":"Price","subheader3":"Amount"})
          this.rightCounterBillingDetails += 1;
        }
        else{
          this.thirdNmExportList.push({"subheader1":"Base","subheader2":"Price","subheader3":"Amount"})
          this.thirdCounterBillingDetails += 1;
        }

        // CONTENT
        base = "null"

        if (x.unitcalculationbase != "null"){
          base = x.calculationbase + " " + x.unitcalculationbase
        }
        else{
          base = x.calculationbase
        }

        if (this.rightCounterBillingDetails < this.totalHeightRight){
          this.rightNmExportList.push({"title":x.conceptname,"content1":base,"content2":x.price,"content3":"null"})
          this.rightCounterBillingDetails += 1;
        }
        else{
          this.thirdNmExportList.push({"title":x.conceptname,"content1":base,"content2":x.price,"content3":"null"})
          this.thirdCounterBillingDetails += 1;
        }

        // TOTAL
        if (this.rightCounterBillingDetails < this.totalHeightRight){
          this.rightNmExportList.push({"total":"TOTAL NET METERING EXPORT ENERGY AMOUNT","content1":x.amountconcept})
          this.rightCounterBillingDetails += 1;
        }
        else{
          this.thirdNmExportList.push({"total":"TOTAL NET METERING EXPORT ENERGY AMOUNT","content1":x.amountconcept})
          this.thirdCounterBillingDetails += 1;
        }

        stop = true
      }
    })
  }

  public otherCharges(content){
    let bill_note_data:any;
    let totalamount:any;
    let concepts:any;
    let conceptName:any;
    let amountConcept:any;
    let calculationBase:any;
    let price:any;

    bill_note_data = content.electricbillsummary.accountentry.bill_notes_list.service.bill_note_data
    if(Array.isArray(bill_note_data)){
      bill_note_data = bill_note_data.slice(1, bill_note_data.length)

      bill_note_data.forEach(data => {
        // TITLE
        // add the header to the array Content
        if (this.rightCounterBillingDetails < this.totalHeightRight){
          this.rightOtherChargesList.push({"header":"Other Charges"})
          this.rightCounterBillingDetails += 1;
        }
        else{
          this.thirdOtherChargesList.push({"header":"Other Charges"})
          this.thirdCounterBillingDetails += 1;
        }

        // add the subheader to the array Content
        if (this.rightCounterBillingDetails < this.totalHeightRight){
          this.rightOtherChargesList.push({"subheader1":"Base","subheader2":"Price","subheader3":"Amount"})
          this.rightCounterBillingDetails += 1;
        }
        else{
          this.thirdOtherChargesList.push({"subheader1":"Base","subheader2":"Price","subheader3":"Amount"})
          this.thirdCounterBillingDetails += 1;
        }

        // CONTENT
        concepts = data.contractdata.meralcoconceptslist.concept
        concepts.forEach(concept => {
          conceptName     = concept.conceptname
          amountConcept   = concept.amountconcept
          calculationBase = concept.calculationbase
          price           = concept.price

          if (price != "null"){
            if (this.rightCounterBillingDetails < this.totalHeightRight){
              this.rightOtherChargesList.push({"title":conceptName,"content1":calculationBase,"content2":price,"content3":amountConcept})
              this.rightCounterBillingDetails += 1;
            }
            else{
              this.thirdOtherChargesList.push({"title":conceptName,"content1":calculationBase,"content2":price,"content3":amountConcept})
              this.thirdCounterBillingDetails += 1;
            }
          }
        }) //  END CONCEPTS

        // TOTAL
        totalamount = data.billnotedata.totalamount
        if (this.rightCounterBillingDetails < this.totalHeightRight){
          this.rightOtherChargesList.push({"total":"TOTAL OTHER CHARGES","content1":totalamount})
          this.rightCounterBillingDetails += 1;
        }
        else{
          this.thirdOtherChargesList.push({"total":"TOTAL OTHER CHARGES","content1":totalamount})
          this.thirdCounterBillingDetails += 1;
        }

      }) // END bill_note_data
    }
  }

  public appliedCredits(content){
    let creditsummary: any;
    let creditdata: any;
    let credittype: any;
    let creditamount: any;
    let credittotal: any;

    if (content.electricbillsummary.accountentry.bill_notes_list.service.creditsummary){
      // TITLE
      // add the header to the array Content
      if (this.rightCounterBillingDetails < this.totalHeightRight){
        this.rightAppliedCreditsList.push({"header":"Applied Credits"})
        this.rightCounterBillingDetails += 1;
      }
      else{
        this.thirdAppliedCreditsList.push({"header":"Applied Credits"})
        this.thirdCounterBillingDetails += 1;
      }

      // add the subheader to the array Content
      if (this.rightCounterBillingDetails < this.totalHeightRight){
        this.rightAppliedCreditsList.push({"subheader1":"null","subheader2":"null","subheader3":"Amount"})
        this.rightCounterBillingDetails += 1;
      }
      else{
        this.thirdAppliedCreditsList.push({"subheader1":"null","subheader2":"null","subheader3":"Amount"})
        this.thirdCounterBillingDetails += 1;
      }

      // CONTENT
      creditsummary = content.electricbillsummary.accountentry.bill_notes_list.service.creditsummary
      creditdata    = creditsummary.creditdata

      if(Array.isArray(creditdata)){
        creditdata.forEach(data => {
          credittype    = data.credittype
          creditamount  = data.creditamount

          if (this.rightCounterBillingDetails < this.totalHeightRight){
            this.rightAppliedCreditsList.push({"title":credittype,"content1":"null","content2":"null","content3":creditamount})
            this.rightCounterBillingDetails += 1;
          }
          else{
            this.thirdAppliedCreditsList.push({"title":credittype,"content1":"null","content2":"null","content3":creditamount})
            this.thirdCounterBillingDetails += 1;
          }
        })
      }else{
        credittype    = creditdata.credittype
        creditamount  = creditdata.creditamount

        if (this.rightCounterBillingDetails < this.totalHeightRight){
          this.rightAppliedCreditsList.push({"title":credittype,"content1":"null","content2":"null","content3":creditamount})
          this.rightCounterBillingDetails += 1;
        }
        else{
          this.thirdAppliedCreditsList.push({"title":credittype,"content1":"null","content2":"null","content3":creditamount})
          this.thirdCounterBillingDetails += 1;
        }
      }

      // TOTAL
      credittotal   = creditsummary.credittotal
      if (this.rightCounterBillingDetails < this.totalHeightRight){
        this.rightAppliedCreditsList.push({"total":"TOTAL OTHER CHARGES","content1":credittotal})
        this.rightCounterBillingDetails += 1;
      }
      else{
        this.thirdAppliedCreditsList.push({"total":"TOTAL OTHER CHARGES","content1":credittotal})
        this.thirdCounterBillingDetails += 1;
      }

    }
  }

  public totalBill(content){
    let billAmount:any;
    billAmount = content.electricbillsummary.accountentry.bill_notes_list.service.invoicesummary.noticetotalamount;

    if (billAmount){
      // // HEADER TITLE
      // if (this.rightCounterBillingDetails < this.totalHeightRight){
      //   this.rightTotalBillList.push({"header":"Total Bill"})
      //   this.rightCounterBillingDetails += 1;
      // }
      // else{
      //   this.thirdTotalBillList.push({"header":"Total Bill"})
      //   this.thirdCounterBillingDetails += 1;
      // }

      // // HEADER SPACE
      // if (this.rightCounterBillingDetails < this.totalHeightRight){
      //   this.rightTotalBillList.push({"Space":"br"})
      //   this.rightCounterBillingDetails += 1;
      // }
      // else{
      //   this.thirdTotalBillList.push({"Space":"br"})
      //   this.thirdCounterBillingDetails += 1;
      // }
      
      // add the header to the array Content
      if (this.rightCounterBillingDetails < this.totalHeightRight){
        this.rightTotalBillList.push({"billAmount":billAmount})
        this.rightCounterBillingDetails += 2;
      }
      else{
        this.thirdTotalBillList.push({"billAmount":billAmount})
        this.thirdCounterBillingDetails += 2;
      }

      // HEADER btm space
      if (this.rightCounterBillingDetails < this.totalHeightRight){
        this.rightTotalBillList.push({"Space":"br"})
        this.rightCounterBillingDetails += 1;
      }
      else{
        this.thirdTotalBillList.push({"Space":"br"})
        this.thirdCounterBillingDetails += 1;
      }

      console.log(">>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>.")
      console.log(this.thirdTotalBillList)
    }
  }

  public additionalBillInformation(content){
    // WIDTH TO WRAP THE TEXT IN 71 TEXT SIZE
    let maxTablewidth = 71;
    let bill_note_data:any;
    let informations:any;
    let servicemessage:any;
    let billMessageArea3:any;
    bill_note_data = content.electricbillsummary.accountentry.bill_notes_list.service.bill_note_data
    informations   = bill_note_data.messagesdata.additionalinformationlist.addinfo;

    if(informations){
      if(Array.isArray(bill_note_data)){
        bill_note_data = bill_note_data[0]
      }

      if(!Array.isArray(informations)){
        informations = [informations]
      }

      informations.forEach(info => {
        if (this.rightCounterBillingDetails < this.totalHeightRight){
          if (info.information != " ") {
            this.rightAdditionalBillInformation.push(info.information)

            if (info.information.length < maxTablewidth){
              this.rightCounterBillingDetails += 1;
            }
            else{
              this.rightCounterBillingDetails += Math.floor(info.information.length / maxTablewidth);
            }
          }
        }
        else{
          if (info.information != " ") {
            this.thirdAdditionalBillInformation.push(info.information)
            this.thirdCounterBillingDetails += 1;

            if (info.information.length < maxTablewidth){
              this.thirdCounterBillingDetails += 1;
            }
            else{
              this.thirdCounterBillingDetails += Math.floor(info.information.length / maxTablewidth);
            }
          }
        }
      });
      if(content.electricbillsummary.accountentry.bill_notes_list.service.servicemessagelist.message) {
        servicemessage = content.electricbillsummary.accountentry.bill_notes_list.service.servicemessagelist.message;
      } else {
        servicemessage = '';
      }

      if (this.rightCounterBillingDetails < this.totalHeightRight){
        if (servicemessage != "null") {
          this.rightAdditionalBillInformation.push(servicemessage)

          if (servicemessage.length < maxTablewidth){
            this.rightCounterBillingDetails += 1;
          }
          else{
            this.rightCounterBillingDetails += Math.floor(servicemessage.length / maxTablewidth);
          }

        }
      }
      else{
        if (servicemessage != "null") {
          this.thirdAdditionalBillInformation.push(servicemessage)

          if (servicemessage.length < maxTablewidth){
            this.thirdCounterBillingDetails += 1;
          }
          else{
            this.thirdCounterBillingDetails += Math.floor(servicemessage.length / maxTablewidth);
          }
        }
      }

      if(content.electricbillsummary.accountentry.accountmessage.area3.billmessage.area3billmessage) {
        billMessageArea3 = content.electricbillsummary.accountentry.accountmessage.area3.billmessage.area3billmessage;
      } else {
        billMessageArea3 = '';
      }

      if (this.rightCounterBillingDetails < this.totalHeightRight){
        if (billMessageArea3 != "null") {
          this.rightAdditionalBillInformation.push(billMessageArea3)
          if (billMessageArea3.length < maxTablewidth){
            this.rightCounterBillingDetails += 1;
          }
          else{
            this.rightCounterBillingDetails += Math.floor(billMessageArea3.length / maxTablewidth);
          }
        }
      }
      else{
        if (billMessageArea3 != "null") {
          this.thirdAdditionalBillInformation.push(billMessageArea3)

          if (billMessageArea3.length < maxTablewidth){
            this.thirdCounterBillingDetails += 1;
          }
          else{
            this.thirdCounterBillingDetails += Math.floor(billMessageArea3.length / maxTablewidth);
          }
        }
      }

      // if this field have item add counter to the title
      if (this.rightAdditionalBillInformation.length > 0){
        this.rightCounterBillingDetails += 1;
      }
    }

  }

  public additionalAccountInformation(content){
  // WIDTH TO WRAP THE TEXT IN 71 TEXT SIZE
  let maxTablewidth = 71;
  let bill_note_data:any;
  let accountMessageArea3:any;

  bill_note_data = content.electricbillsummary.accountentry.bill_notes_list.service.bill_note_data
  accountMessageArea3 = content.electricbillsummary.accountentry.accountmessage.area3.accountmessage.area3accountmessage;

  if(content.electricbillsummary.accountentry.accountmessage.area3.accountmessage.area3accountmessage) {
    accountMessageArea3 = content.electricbillsummary.accountentry.accountmessage.area3.accountmessage.area3accountmessage;
  } else {
    accountMessageArea3 = '';
  }

  if(accountMessageArea3.length != 0){
    if(Array.isArray(bill_note_data)){
      bill_note_data = bill_note_data[0]
    }


    if (this.rightCounterBillingDetails < this.totalHeightRight){
      if (accountMessageArea3 != "null") {
        this.rightAdditionalAccountInformation.push(accountMessageArea3)
        if (accountMessageArea3.length < maxTablewidth){
          this.rightCounterBillingDetails += 1;
        }
        else{
          this.rightCounterBillingDetails += Math.floor(accountMessageArea3.length / maxTablewidth);
        }
      }
    }
    else{
      if (accountMessageArea3 != "null") {
        this.thirdAdditionalAccountInformation.push(accountMessageArea3)
        if (accountMessageArea3.length < maxTablewidth){
          this.thirdCounterBillingDetails += 1;
        }
        else{
          this.thirdCounterBillingDetails += Math.floor(accountMessageArea3.length / maxTablewidth);
        }
      }
    }
  }

  console.log(this.rightAdditionalAccountInformation)
}


  public hasThird(){
    if(this.rightCounterBillingDetails >= this.totalHeightRight){
      return true
    }
    else{
      return false
    }
  }

}
