import { Injectable } from '@angular/core';
import { HttpHeaders } from '@angular/common/http';
@Injectable({
  providedIn: 'root'
})
export class HttpHeaderService {


	headers: HttpHeaders = new HttpHeaders();
	httpOptions: any;
  constructor() {
	this.headers = this.headers.append('Content-Type', 'application/json');
	this.headers = this.headers.append('MecoAuthorization','');
  }

  setContentType(type){
  	this.headers = this.headers.set('Content-Type',type);
  }

  setMecoAuthorization(token){
  	this.headers = this.headers.set('MecoAuthorization',token);
  }

  getHttpOptions(){
  	this.httpOptions = {
	  headers: this.headers};

	  return this.httpOptions;
  }
}