import { Component, OnInit, Input } from '@angular/core';

@Component({
  selector: 'app-billing-statement-breakdown-of-charges',
  templateUrl: './billing-statement-breakdown-of-charges.component.html',
  styleUrls: ['./billing-statement-breakdown-of-charges.component.scss']
})
export class BillingStatementBreakdownOfChargesComponent implements OnInit {

	@Input() bill:any;

  percentage = []
  billgroupSorted = []
  constructor() { }

  ngOnInit() {
    let total = 0
    let billgroup = this.bill.electricbillsummary.accountentry.bill_notes_list.service.bill_note_data.contractdata.billingsummary.billgrouplist.billgroup
    billgroup.forEach(element => {
      total += Number(element.totalamount)
    });

    billgroup.forEach(element => {
      element["percentage"] = ( (Number(element.totalamount)/total)*100 )
    });

    this.billgroupSorted = this.swapArray(billgroup,billgroup.length-1,billgroup.length-2);
    
  }

  convertToFloat(amount: string){
    return parseFloat(amount)
  }

  numberWithCommas(amount:number) {
    parseFloat(amount.toString())
    var parts = amount.toString().split(".");
    parts[0] = parts[0].replace(/\B(?=(\d{3})+(?!\d))/g, ",");
    return parts.join(".");
  }


  swapArray(Array:any,Swap1:number,Swap2:number) : any {
      var temp = Array[Swap1];
      Array[Swap1] = Array[Swap2]
      Array[Swap2] = temp
      return Array;
  }



}
