import { Component, OnInit, Input } from '@angular/core';
@Component({
  selector: 'app-billing-statement-header',
  templateUrl: './billing-statement-header.component.html',
  styleUrls: ['./billing-statement-header.component.scss']
})
export class BillingStatementHeaderComponent implements OnInit {

	@Input() bill: any;

  constructor() { }

  ngOnInit() {
  }

}
