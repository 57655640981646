import { Component, OnInit, AfterViewInit, Inject } from '@angular/core';
import {PdfGeneratorService} from '../../services/pdf-generator.service';
import {MAT_DIALOG_DATA} from '@angular/material';

@Component({
  selector: 'app-preview-bill',
  templateUrl: './preview-bill.component.html',
  styleUrls: ['./preview-bill.component.scss']
})
export class PreviewBillComponent implements OnInit, AfterViewInit {

  selectedFile: File;
  toggleClass = false;
  toPrint = "";
  url = "";
  updateImage = false;
  billAdImage: any = {};

  constructor(public pdfGeneratorService: PdfGeneratorService, @Inject(MAT_DIALOG_DATA) public bill: any) { }

  pageView = "preview-bill";
  ngOnInit() {
    console.log("FROM TABLE ", this.bill);
    this.toPrint = JSON.stringify((this.bill))
    localStorage.setItem('data',this.toPrint);
    this.url = "";
    this.billAdImage = {url:"", isUploading:false, isDefault:true};
    localStorage.setItem('billAdImage',JSON.stringify(this.billAdImage));
  }

  ngAfterViewInit(){
  	//this.pdfGeneratorService.addPage('front');
  }

  savePdf(){
    this.toggleClass = true;
  	this.pdfGeneratorService.generate('Billing Statement');
  }
  

  onFileChanged(event) {
    this.billAdImage.isDefault = false;
    this.billAdImage.isUploading = true;
    this.selectedFile = event.target.files[0];

    
    var promise = this.getBase64(this.selectedFile);
    promise.then((result) => {
        this.billAdImage.url = result;
        this.billAdImage.isUploading = false;
        localStorage.setItem('billAdImage',JSON.stringify(this.billAdImage));
    });
  }



  getBase64(file) {
    return new Promise(function(resolve, reject) {
        var reader = new FileReader();
        reader.onload = function() { resolve(reader.result); };
        reader.onerror = reject;
        reader.readAsDataURL(file);
    });
}



}
