import { Component, OnInit, AfterViewInit } from '@angular/core';
import { MsAdalAngular6Service } from 'microsoft-adal-angular6';

import { LoaderService } from '../../services/loader.service';

import { HttpHeaderService } from '../../services/http-header.service';

@Component({
  selector: 'app-home',
  templateUrl: './home.component.html',
  styleUrls: ['./home.component.scss']
})
export class HomeComponent implements OnInit, AfterViewInit {

	navs = ['Search Archives', 'Logout'];
	showLoader = false;
	selectedNav: string = this.navs[0];
 constructor(private loaderService: LoaderService, private adalSvc: MsAdalAngular6Service, private httpHeaderService: HttpHeaderService) {
    console.log(this.adalSvc);
    var token = this.adalSvc.acquireToken('https://graph.microsoft.com').subscribe((token: string) => {
    console.log(token);
    this.httpHeaderService.setMecoAuthorization(token);
  });
}
  ngOnInit() {
     
  }

  toggleLoader(){
  	this.showLoader = !this.showLoader;
  }

  ngAfterViewInit(){

  }

  logout(){
    document.location.href = 'https://login.microsoftonline.com/3af66f2a-fa24-4ee0-a212-ca5b8f4e61c7/oauth2/logout?post_logout_redirect_uri=https://billreprint-loa.wms.meco-soldel.com/home';
  }

}
