import { Component, OnInit, Inject, Input, Output, EventEmitter, ViewChild, ChangeDetectorRef } from '@angular/core';
import { SearchRequest } from '../../models/SearchRequest';
import { BillRequestType } from '../../enums/BillRequestType';
import {BillingStatementService} from '../../services/billing-statement.service';
import {DatePipe} from '@angular/common';
import {MatDialog, MatDialogRef, MAT_DIALOG_DATA} from '@angular/material';
import { PreviewBillComponent } from '../../pages/preview/preview-bill.component';
import { LoaderService } from '../../services/loader.service';
import { ajax } from 'rxjs/ajax';
import { LoaTableComponent } from '../../widgets/loa-table/loa-table.component';


@Component({
  selector: 'app-search-archives',
  templateUrl: './search-archives.component.html',
  styleUrls: ['./search-archives.component.scss']
})
export class SearchArchivesComponent implements OnInit {

  @ViewChild(LoaTableComponent)

  @Output()
  toggleLoader = new EventEmitter<any>();

  request : SearchRequest;
	criterias = [{type:BillRequestType.SIN, description:'Service ID Number'},
  {type:BillRequestType.ACCOUNT, description:'Account Number'} ,
  {type:BillRequestType.BILL, description:'Bill Number'},
  {type:BillRequestType.STATEMENT, description:'Statement Number'}];
	filterCriterias = [{description:'All',type:'all'},{description:'Bill Number',type:'bill_num'}, {description:'Statement Number', type:'statement_num'}];

   searchableCriteria = {'type':undefined, 'dateEnable':false, 'values':{'value':'','dateFrom':'','dateTo':'','billDate':''}};

  isValidated = true;
  doDisableButton = true;
  noResult = false;
  hasSearch = false;

  @Input() ELEMENT_DATA: any[] = [];
  UNFILTERED_DATA: any[] = [];

  @Input() columns: string[] = [  'statement_num', 'bill_num', 'creation_date'];
  @Input() headers: string[] = [ 'Statement Number', 'Bill Number', 'Bill Date'];
  doPrint: boolean = false;
  constructor(private cdr: ChangeDetectorRef, private billingStatementService : BillingStatementService, private datePipe: DatePipe,
    public dialog: MatDialog, private loaderService: LoaderService) { }

  ngOnInit() {
    this.request = this.clear();
    this.noResult = false;
    this.hasSearch = false;
    // this.searchableCriteria = {'type':undefined, 'dateEnable':false, 'values':{'value':'310080310101','dateFrom':'10/01/2015','dateTo':'12/30/2015'}};
    this.disableSend();
  }

  onChangeCriterias(index){
    var value = this.criterias[index-1];
    this.searchableCriteria.dateEnable = false;
    this.searchableCriteria.type = value;
    switch(value){
      case this.criterias[0]:
      case this.criterias[1]:
        this.searchableCriteria.dateEnable = true;
        break;
    }

    this.disableSend();
  }

  disableSend(){
    this.doDisableButton = this.searchableCriteria.values.value == "" && (this.searchableCriteria.dateEnable == true || (this.searchableCriteria.values.dateFrom == "" && this.searchableCriteria.values.value == "") );
  }

  emitDisableSend(){
    console.log("i am emitting");
    this.disableSend();
  }

  toggleResult(){
  	this.noResult = !this.noResult;
  }

  appendTableColumns(){
    this.ELEMENT_DATA.forEach(obj=>{
     
      obj["doPrint"] = false;
      obj["billAdImage"] = 'N/A';
    });
    console.log('APPEND TABLE COLUMNS: ' + JSON.stringify(this.ELEMENT_DATA))
  }
  
  getResult(){
    //this.toggleLoader.emit();
   
    this.getInputs();
    if(this.isValidated){
       this.loaderService.toggle();


       if(this.request.type == 'bill' || this.request.type =='statement'){
          this.billingStatementService.getBilling(this.request).subscribe((data: any) =>{
            
             if(data["electricbillsummary"] != undefined){

              const dialogRef = this.dialog.open(PreviewBillComponent, {
                width: '9.267in',
                height: '8.267in',
                data: data
              });

              dialogRef.afterClosed().subscribe(result => {
                console.log('The dialog was closed');
                localStorage.removeItem('data');
               // this.animal = result;
              });
               this.loaderService.toggle();
             }else{
             this.noResult = false;
             this.loaderService.toggle();
             }
          });
       }else{
          this.getTable(this.request);
       }
      
    }
    this.hasSearch = true;
  }
  
getTable(request){
    this.billingStatementService.getBilling(request).subscribe((data: any) =>{
           var noResult = '{"response":{"result":"no record found."}}';
           if(data["length"] != undefined){
           this.ELEMENT_DATA = data;
           console.log("current data: ", this.ELEMENT_DATA);
            this.appendTableColumns();
            this.UNFILTERED_DATA = this.ELEMENT_DATA;
            this.noResult = true;
            this.cdr.detectChanges();
           }else{
             if(JSON.stringify(data) !== noResult){
              var arrayData = new Array();
              arrayData[0] = data;
              this.ELEMENT_DATA = arrayData;
              console.log("current data: ", this.ELEMENT_DATA);
              this.appendTableColumns();
              this.UNFILTERED_DATA = this.ELEMENT_DATA;
              this.noResult = true;
              this.cdr.detectChanges();
             }
             else{
              this.noResult = false;
             }
           }
           this.loaderService.toggle();
        });
  }

  setFiltered(bills){

    this.ELEMENT_DATA = bills;
    this.noResult = bills.length > 0;
  }

  getInputs(){
    this.request = this.clear();
    this.request.type = this.searchableCriteria.type == undefined ? "":this.searchableCriteria.type.type;
    this.isValidated = false;
    console.log(JSON.stringify('this.searchableCriteria'));
    console.log(JSON.stringify(this.searchableCriteria));
    switch(this.request.type){

      case BillRequestType.SIN:
        this.request.sin = this.searchableCriteria.values.value.toString();
        this.request.billdate = +(this.datePipe.transform(this.searchableCriteria.values.billDate, 'MMddyy'));

        console.log(JSON.stringify(this.request));
        this.isValidated = this.request.sin != '' && this.request.billdate != '';
        console.log(this.isValidated);
        break;

      case BillRequestType.ACCOUNT:
        this.request.account_num = this.searchableCriteria.values.value.toString();
        this.request.billdate = +(this.datePipe.transform(this.searchableCriteria.values.billDate, 'MMddyy'));

        console.log(JSON.stringify(this.request));
        this.isValidated = this.request.account_num != '' && this.request.billdate != '';
        console.log(this.isValidated);
        break;

      case BillRequestType.BILL:
        this.request.bill_num = this.searchableCriteria.values.value.toString();
        this.request.billdate = +(this.datePipe.transform(this.searchableCriteria.values.billDate, 'MMddyy'));

        console.log(JSON.stringify(this.request));
        this.isValidated = this.request.bill_num != '' && this.request.billdate != '';
        console.log(this.isValidated);
        break;

      case BillRequestType.STATEMENT:
        this.request.statement_num = this.searchableCriteria.values.value.toString();
        this.request.billdate = +(this.datePipe.transform(this.searchableCriteria.values.billDate, 'MMddyy'));

        console.log(JSON.stringify(this.request));
        this.isValidated = this.request.statement_num != '' && this.request.billdate != '';
        console.log(this.isValidated);
        break;
    }

    // OLD CODE
    // switch(this.request.type){

    //   case BillRequestType.SIN:
    //     this.request.sin = this.searchableCriteria.values.value.toString();
    //     this.request.from_date = this.datePipe.transform(this.searchableCriteria.values.dateFrom, 'MM/dd/yyyy');
    //     this.request.to_date = this.datePipe.transform(this.searchableCriteria.values.dateTo, 'MM/dd/yyyy');

    //     console.log(JSON.stringify(this.request));

    //     this.isValidated = this.request.sin != '' && this.request.from_date != null && this.request.to_date != null;
    //     console.log(this.isValidated);
    //     break;
    //   case BillRequestType.ACCOUNT:
    //     this.request.account_num = this.searchableCriteria.values.value.toString();
    //     this.request.from_date = this.datePipe.transform(this.searchableCriteria.values.dateFrom, 'MM/dd/yyyy');
    //     this.request.to_date = this.datePipe.transform(this.searchableCriteria.values.dateTo, 'MM/dd/yyyy');

    //     this.isValidated = this.request.account_num != '' && this.request.from_date != null && this.request.to_date != null;
    //     break;
    //   case BillRequestType.BILL:
    //     this.request.bill_num = this.searchableCriteria.values.value.toString();

    //     this.isValidated = this.request.bill_num != '';
    //     break;
    //   case BillRequestType.STATEMENT:
    //     this.request.statement_num = this.searchableCriteria.values.value.toString();

    //     this.isValidated = this.request.statement_num != '';
    //     break;
    // }
  }

  // display(col){
  //   ajax({
  //     url: "assets/nm.json",
  //     crossDomain: true
  //   }).subscribe(response => {
  //     this.loaderService.toggle();
  //     this.billingStatementService.getBilling(this.view(col)).subscribe((data: any) =>{
  //                const dialogRef = this.dialog.open(PreviewBillComponent, {
  //                   width: '9.267in',
  //                   height: '8.267in',
  //                   data: response.response
  //                 });

  //                 dialogRef.afterClosed().subscribe(result => {
  //                   console.log('The dialog was closed');
  //                  // this.animal = result;
  //                 });
  //                  this.loaderService.toggle();
  //               });
  //   });

  // }

  display(col){
     this.loaderService.toggle();
     console.log("Request: ", col)
    this.billingStatementService.getBilling(this.view(col)).subscribe((data: any) =>{
                 const dialogRef = this.dialog.open(PreviewBillComponent, {
                    width: '9.267in',
                    height: '8.267in',
                    data: data
                  });

                  dialogRef.afterClosed().subscribe(result => {
                    console.log('The dialog was closed');
                    localStorage.removeItem('data');
                   // this.animal = result;
                  });
                   this.loaderService.toggle();
                });
  }

  clear(){
    return {
      "type": "",
      "bill_num": "",
      "sin": "",
      "account_num": "",
      "statement_num": "",
      "from_date": "",
      "to_date": "",
      "billdate": ""};
  }


  view(obj){

    return{
      "type":"bill",
      "bill_num" : obj.bill_num,
      "sin": "",
      "account_num": "",
      "statement_num":obj.statement_num,
      "from_date": "",
      "to_date": "",
      "billdate": obj.billdate
    };
  }
}
