import { Component, OnInit, Input } from '@angular/core';
import { map, subscribeOn } from 'rxjs/operators';
import { Observable } from 'rxjs';
import { ajax } from 'rxjs/ajax';


@Component({
  selector: 'app-billing-statement-billing-details-left',
  templateUrl: './billing-statement-billing-details-left.component.html',
  styleUrls: ['./billing-statement-billing-details-left.component.scss']
})

export class BillingStatementBillingDetailsLeftComponent implements OnInit {


  @Input()
  leftContent = []

  constructor() {
  }

  ngOnInit() {
  }

  
}