import { Component, OnInit, Input } from '@angular/core';

@Component({
  selector: 'app-billing-statement-additional-account-info',
  templateUrl: './billing-statement-additional-account-info.component.html',
  styleUrls: ['./billing-statement-additional-account-info.component.scss']
})
export class BillingStatementAdditionalAccountInfoComponent implements OnInit {

  @Input()
  rightAdditionalAccountInformation = []

  constructor() { }

  ngOnInit() {
    console.log("asdddddddddasdsad")
    console.log(this.rightAdditionalAccountInformation)
  }

}
