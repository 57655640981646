import { Component, OnInit, Input } from '@angular/core';

@Component({
  selector: 'app-billing-statement-electric-bill-line',
  templateUrl: './billing-statement-electric-bill-line.component.html',
  styleUrls: ['./billing-statement-electric-bill-line.component.scss']
})
export class BillingStatementElectricBillLineComponent implements OnInit {

	@Input() bill:any;

  constructor() { }

  ngOnInit() {
  }

}
