import { Component, OnInit, Input, Output, EventEmitter } from '@angular/core';

@Component({
  selector: 'app-search-criteria',
  templateUrl: './search-criteria.component.html',
  styleUrls: ['./search-criteria.component.scss']
})
export class SearchCriteriaComponent implements OnInit {

	@Input() searchableCriteria = {'type':undefined, 'dateEnable':false, 'values':{'value':'','dateFrom':null,'dateTo':null}};
	@Output()
  disableButton = new EventEmitter<any>();

  constructor() { }

  ngOnInit() {

  }

  changeModels(){
  	this.disableButton.emit();
  }

}
