import { Component, OnInit, Input } from '@angular/core';

@Component({
  selector: 'app-billing-statement-billing-details-right',
  templateUrl: './billing-statement-billing-details-right.component.html',
  styleUrls: ['./billing-statement-billing-details-right.component.scss']
})
export class BillingStatementBillingDetailsRightComponent implements OnInit {

  @Input()
  rightContent = []

  constructor() { }

  ngOnInit() {

  }

}
