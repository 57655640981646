import { Component, OnInit, Input } from '@angular/core';

@Component({
  selector: 'app-billing-statement-other-charges',
  templateUrl: './billing-statement-other-charges.component.html',
  styleUrls: ['./billing-statement-other-charges.component.scss']
})
export class BillingStatementOtherChargesComponent implements OnInit {

  @Input() rightOtherChargesList = [];
  // @Input() thirdOtherChargesList = [];
  
  constructor() { }

  ngOnInit() {
  }

}
