import { Injectable } from '@angular/core';
import * as jspdf from 'jspdf';  
import html2canvas from 'html2canvas';  
import { LoaderService } from '../services/loader.service';


@Injectable({
  providedIn: 'root'
})
export class PdfGeneratorService {


	private pdf;

  constructor(private loaderService: LoaderService) {
  	this.pdf = new jspdf('p','mm','a4',true);
  }

  addPage(id){
	var data = document.getElementById(id);  
    html2canvas(data).then(canvas => {  
      // Few necessary setting options  
      var imgWidth = 208;   
      var pageHeight = 295;    
      var imgHeight = canvas.height * imgWidth / canvas.width;  
      var heightLeft = imgHeight;  
  
      const contentDataURL = canvas.toDataURL('image/png');
      var position = 0;  
      this.pdf.addImage(contentDataURL, 'PNG', 0, position, imgWidth, imgHeight,undefined,'FAST'); 
    });  
  }

  generate(name){
  	 var n = name +'.pdf';
  	// this.pdf.save(n);
     this.loaderService.toggle();

  	var data = document.getElementById('one');  
    html2canvas(data).then(canvas => {  
      // Few necessary setting options  
      var imgWidth = 208;   
      var pageHeight = 295;    
      var imgHeight = canvas.height * imgWidth / canvas.width;  
      var heightLeft = imgHeight;  
  
      const contentDataURL = canvas.toDataURL('image/png');
      var position = 0;  
      this.pdf.addImage(contentDataURL, 'PNG', 0, position, imgWidth, imgHeight,undefined,'FAST'); 
      this.pdf.addPage();



      var data1 = document.getElementById('two');  
    html2canvas(data1).then(canvas1 => {  
      // Few necessary setting options  
      var imgWidth1 = 208;   
      var pageHeight1 = 295;    
      var imgHeight1 = canvas1.height * imgWidth1 / canvas1.width;  
      var heightLeft1 = imgHeight1;  
  
      const contentDataURL1 = canvas1.toDataURL('image/png');
      var position1 = 0;  
      this.pdf.addImage(contentDataURL1, 'PNG', 0, position1, imgWidth1, imgHeight1,undefined,'FAST'); 
      this.pdf.addPage();


      var data2 = document.getElementById('three');  
      if(data2 != null){
        html2canvas(data2).then(canvas2 => {  
        this.loaderService.toggle();
        // Few necessary setting options  
        var imgWidth2 = 208;   
        var pageHeight2 = 295;    
        var imgHeight2 = canvas2.height * imgWidth2 / canvas2.width;  
        var heightLeft2 = imgHeight2;  
    
        const contentDataURL2 = canvas2.toDataURL('image/png');
        var position2 = 0;  
        this.pdf.addImage(contentDataURL2, 'PNG', 0, position2, imgWidth2, imgHeight2,undefined,'FAST'); 
        this.pdf.addPage();
        this.pdf.save(n);
          }); 
      }else{
        this.pdf.save(n);
      }
     

        });  

        });  
  }
}
