import { BrowserModule }              from '@angular/platform-browser';
import { NgModule }                   from '@angular/core';
import { HttpClientModule }           from '@angular/common/http';
import { DatePipe }                   from '@angular/common';
import { ChartsModule }               from 'ng2-charts';
import { AppComponent }               from './app.component';
import { AppRoutingModule }           from './/app-routing.module';
import { BrowserAnimationsModule }    from '@angular/platform-browser/animations';
import { AuthenticationGuard, MsAdalAngular6Module } from 'microsoft-adal-angular6';
import { NgxBarcodeModule } from 'ngx-barcode';

import { BillingStatementAccountSummaryComponent }            from './pages/bill/page-one/billing-statement-account-summary/billing-statement-account-summary.component';
import { BillingStatementBillingInformationComponent }        from './pages/bill/page-one/billing-statement-billing-information/billing-statement-billing-information.component';
import { BillingStatementBreakdownOfChargesComponent }        from './pages/bill/page-one/billing-statement-breakdown-of-charges/billing-statement-breakdown-of-charges.component';
import { BillingStatementConsumptionChartComponent }          from './pages/bill/page-one/billing-statement-consumption-chart/billing-statement-consumption-chart.component';
import { BillingStatementElectricBillLineComponent }          from './pages/bill/page-one/billing-statement-electric-bill-line/billing-statement-electric-bill-line.component';
import { BillingStatementHeaderComponent }                    from './pages/bill/page-one/billing-statement-header/billing-statement-header.component';
import { BillingStatementReferenceSectionComponent }          from './pages/bill/page-one/billing-statement-reference-section/billing-statement-reference-section.component';
import { BillingStatementAdditionalAccountInfoComponent }     from './pages/bill/page-two/billing-statement-additional-account-info/billing-statement-additional-account-info.component';
import { BillingStatementAdditionalBillInformationComponent } from './pages/bill/page-two/billing-statement-additional-bill-information/billing-statement-additional-bill-information.component';
import { BillingStatementAppliedCreditsComponent }            from './pages/bill/page-two/billing-statement-applied-credits/billing-statement-applied-credits.component';
import { BillingStatementBillingDetailsLeftComponent }        from './pages/bill/page-two/billing-statement-billing-details-left/billing-statement-billing-details-left.component';
import { BillingStatementBillingDetailsRightComponent }       from './pages/bill/page-two/billing-statement-billing-details-right/billing-statement-billing-details-right.component';
import { BillingStatementEnergyBillAmountComponent }          from './pages/bill/page-two/billing-statement-energy-bill-amount/billing-statement-energy-bill-amount.component';
import { BillingStatementMeteringInfoComponent }              from './pages/bill/page-two/billing-statement-metering-info/billing-statement-metering-info.component';
import { BillingStatementNmExportComponent }                  from './pages/bill/page-two/billing-statement-nm-export/billing-statement-nm-export.component';
import { BillingStatementOtherChargesComponent }              from './pages/bill/page-two/billing-statement-other-charges/billing-statement-other-charges.component';
import { BillingStatementReferenceSectionTwoComponent }       from './pages/bill/page-two/billing-statement-reference-section-two/billing-statement-reference-section-two.component';
import { BillingStatementTotalBillComponent }                 from './pages/bill/page-two/billing-statement-total-bill/billing-statement-total-bill.component';

import { HomeComponent }                                      from './pages/home/home.component';
import { LogsComponent }                                      from './pages/logs/logs.component';
import { SearchArchivesComponent }                            from './pages/search-archives/search-archives.component';
import { PreviewBillComponent }                               from './pages/preview/preview-bill.component';

import { BillingStatementService }                            from './services/billing-statement.service';
import { LoaderService }                                      from './services/loader.service';
import { HttpHeaderService }                                  from './services/http-header.service';

import { FilterCriteriaComponent }                            from './widgets/filter-criteria/filter-criteria.component';
import { LoaTableComponent }                                  from './widgets/loa-table/loa-table.component';
import { SearchCriteriaComponent }                            from './widgets/search-criteria/search-criteria.component';
import { BillingStatementStaticFooterComponent }              from './pages/bill/page-two/billing-statement-static-footer/billing-statement-static-footer.component';

import { BillingStatementHeaderPageThreeComponent }           from './pages/bill/page-three/billing-statement-header-page-three/billing-statement-header-page-three.component';

import { PrintViewComponent }                                 from './pages/print-view/print-view.component';
import { PageThreeComponent } from './pages/bill/page-three/page-three/page-three.component';
import { PageTwoComponent } from './pages/bill/page-two/page-two/page-two.component';

import { LoaderComponent } from './widgets/loader/loader.component';
import { PageOneComponent } from './pages/bill/page-one/page-one/page-one.component';
import { BackParentComponent } from './pages/bill/back-parent/back-parent.component';

import { SearchPipe } from './pipes/search-pipe';

@NgModule({
  declarations: [
    AppComponent,
    HomeComponent,
    BillingStatementHeaderComponent,
    BillingStatementAccountSummaryComponent,
    BillingStatementElectricBillLineComponent,
    SearchArchivesComponent,
    FilterCriteriaComponent,
    BillingStatementReferenceSectionTwoComponent,
    BillingStatementMeteringInfoComponent,
    BillingStatementBillingDetailsLeftComponent,
    BillingStatementBillingDetailsRightComponent,
    LogsComponent,
    SearchCriteriaComponent,
    LoaTableComponent,
    BillingStatementBillingInformationComponent,
    BillingStatementBreakdownOfChargesComponent,
    BillingStatementConsumptionChartComponent,
    BillingStatementReferenceSectionComponent,
    BillingStatementEnergyBillAmountComponent,
    BillingStatementNmExportComponent,
    BillingStatementOtherChargesComponent,
    BillingStatementAppliedCreditsComponent,
    BillingStatementTotalBillComponent,
    BillingStatementAdditionalBillInformationComponent,
    BillingStatementStaticFooterComponent,
    PrintViewComponent,
    BillingStatementHeaderPageThreeComponent,
    PageThreeComponent,
    PageTwoComponent,
    PreviewBillComponent,
    LoaderComponent,
    PageOneComponent,
    BackParentComponent,
    SearchPipe,
    BillingStatementAdditionalAccountInfoComponent,
  ],
  imports: [
    BrowserModule,
    AppRoutingModule,
    BrowserAnimationsModule,
    HttpClientModule,
    ChartsModule,
    NgxBarcodeModule,
    MsAdalAngular6Module.forRoot({
      tenant: '3af66f2a-fa24-4ee0-a212-ca5b8f4e61c7',
      clientId: '09165484-5ec6-4e06-9439-ce01b6925b45',
      redirectUri: 'https://billreprint-loa.wms.meco-soldel.com/home',
      endpoints: {
        "https://billreprint-loa.wms.meco-soldel.com/home": "09165484-5ec6-4e06-9439-ce01b6925b45"
      },
      navigateToLoginRequestUrl: false,
      cacheLocation: 'localStorage',
    })
    
  ],
   entryComponents: [PreviewBillComponent],
  providers: [BillingStatementService, DatePipe, LoaderService, HttpHeaderService, AuthenticationGuard],
  bootstrap: [AppComponent]
})
export class AppModule { }
