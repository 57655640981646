import { Component, OnInit } from '@angular/core';
import { ActivatedRoute } from '@angular/router'

@Component({
  selector: 'app-print-view',
  templateUrl: './print-view.component.html',
  styleUrls: ['./print-view.component.scss']
})
export class PrintViewComponent implements OnInit {
  bill:any;
  pageView = "print-view";
  billAdImage: any = {};
  constructor(public route:ActivatedRoute) {

  }

  ngOnInit() {
    
   
    // this.route.queryParams.subscribe(params => {
    //   this.bill = JSON.parse((params.data))
    // });
    this.billAdImage = JSON.parse(localStorage.getItem('billAdImage'));
    this.bill = JSON.parse(localStorage.getItem('data'));
    // window.print();

    
  }

  async ngAfterViewInit(){
    console.log("print")
    // this.billAdImage = JSON.parse(localStorage.getItem('billAdImage'));
    // this.bill = JSON.parse(localStorage.getItem('data'));
    // window.print();
    await delay(1500);
    window.print();

  }

}

async function delay(ms: number) {
  return new Promise( resolve => setTimeout(resolve, ms) );
}
