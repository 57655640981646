import { Component, OnInit, Input } from '@angular/core';
import * as moment from 'moment';

@Component({
  selector: 'app-billing-statement-billing-information',
  templateUrl: './billing-statement-billing-information.component.html',
  styleUrls: ['./billing-statement-billing-information.component.scss']
})
export class BillingStatementBillingInformationComponent implements OnInit {

  @Input() bill: any;
  
  billDate: string;
  billFrom: string;
  billTo: string;
  billDueDate: string;
  totalCurrentAmount: string;
  totalKWH: Number;
  nextMeterReadingDate: string;
  meterReadingDate: string;
  rate: string;
  rateToRemove = "Flat Streetlights"
  constructor() { }

  ngOnInit() {
    this.billDate = moment(this.bill.electricbillsummary.accountentry.bill_notes_list.service.bill_note_data.billnotedata.creationdate).format('DD MMM YYYY');
    this.billTo = moment(this.bill.electricbillsummary.accountentry.bill_notes_list.service.bill_note_data.billnotedata.billingperiodto).format('DD MMM YYYY');
    this.billFrom = moment(this.bill.electricbillsummary.accountentry.bill_notes_list.service.bill_note_data.billnotedata.billingperiodfrom).format('DD MMM YYYY');
    this.billDueDate = moment(this.bill.electricbillsummary.accountentry.bill_notes_list.service.invoicesummary.invoiceduedate).format('DD MMM YYYY');
    this.nextMeterReadingDate = moment(this.bill.electricbillsummary.accountentry.bill_notes_list.service.bill_note_data.contractdata.nextschedulemeterread).format('DD MMM YYYY');
    this.meterReadingDate = moment(this.bill.electricbillsummary.accountentry.bill_notes_list.service.bill_note_data.contractdata.meterreaddate).format('DD MMM YYYY');
    this.totalCurrentAmount = this.numberWithCommas(parseFloat(this.bill.electricbillsummary.accountentry.bill_notes_list.service.invoicesummary.noticetotalamount));
    this.totalKWH = parseInt(this.bill.electricbillsummary.accountentry.bill_notes_list.service.bill_note_data.contractdata.readingslist.reading[0].usage, 10);
    this.rate =  this.bill.electricbillsummary.accountentry.bill_notes_list.service.bill_note_data.contractdata.ratelist.rate.ratename;
  }

  numberWithCommas(amount:number) {
    var parts = amount.toString().split(".");
    parts[0] = parts[0].replace(/\B(?=(\d{3})+(?!\d))/g, ",");
    return parts.join(".");
  }

}
