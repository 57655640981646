import { Component, OnInit, Input } from '@angular/core';

@Component({
  selector: 'app-billing-statement-applied-credits',
  templateUrl: './billing-statement-applied-credits.component.html',
  styleUrls: ['./billing-statement-applied-credits.component.scss']
})
export class BillingStatementAppliedCreditsComponent implements OnInit {

  @Input() rightAppliedCreditsList = [];
  // @Input() thirdAppliedCreditsList = [];
  
  constructor() { }

  ngOnInit() {
  }

}
