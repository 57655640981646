import { Component, OnInit, Input } from '@angular/core';
import { ElementRef, ViewChild } from '@angular/core';
import { ajax } from 'rxjs/ajax';

@Component({
  selector: 'app-page-two',
  templateUrl: './page-two.component.html',
  styleUrls: ['./page-two.component.scss']
})
export class PageTwoComponent implements OnInit {

  @Input() referenceSectionTwoList:any;
  @Input() displayKWH:any;
  @Input() meteringInfoList:any;
  @Input() leftBillingDetails:any;
  @Input() rightBillingDetails:any;
  @Input() rightEnergyBillAmountList:any;
  @Input() rightNmExportList:any;
  @Input() rightOtherChargesList:any;
  @Input() rightAppliedCreditsList:any;
  @Input() rightTotalBillList:any;
  @Input() rightAdditionalBillInformation:any;
  @Input() rightAdditionalAccountInformation:any;
  @Input() bill:any;
  constructor() { }

  ngOnInit() {
  }

}
