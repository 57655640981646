import { Component, OnInit } from '@angular/core';

@Component({
  selector: 'app-logs',
  templateUrl: './logs.component.html',
  styleUrls: ['./logs.component.scss']
})
export class LogsComponent implements OnInit {

	filterCriterias = ['All','Acitivty', 'Login', 'Reason', 'Bill Number'];

ELEMENT_DATA: any[] = [
  {activity:'Log In', login:'user@domain.com.ph', reason:'no reason', billNumber:1234567890, timeStamp:'08/17/2018'},
  {activity:'Log In', login:'user@domain.com.ph', reason:'no reason', billNumber:1234567890, timeStamp:'08/17/2018'},
  {activity:'Log In', login:'user@domain.com.ph', reason:'no reason', billNumber:1234567890, timeStamp:'08/17/2018'},
  {activity:'Log In', login:'user@domain.com.ph', reason:'no reason', billNumber:1234567890, timeStamp:'08/17/2018'},
  {activity:'Log In', login:'user@domain.com.ph', reason:'no reason', billNumber:1234567890, timeStamp:'08/17/2018'},
  {activity:'Log In', login:'user@domain.com.ph', reason:'no reason', billNumber:1234567890, timeStamp:'08/17/2018'},
  {activity:'Log In', login:'user@domain.com.ph', reason:'no reason', billNumber:1234567890, timeStamp:'08/17/2018'},
  {activity:'Log In', login:'user@domain.com.ph', reason:'no reason', billNumber:1234567890, timeStamp:'08/17/2018'},
  {activity:'Log In', login:'user@domain.com.ph', reason:'no reason', billNumber:1234567890, timeStamp:'08/17/2018'},
  {activity:'Log In', login:'user@domain.com.ph', reason:'no reason', billNumber:1234567890, timeStamp:'08/17/2018'},
  {activity:'Log In', login:'user@domain.com.ph', reason:'no reason', billNumber:1234567890, timeStamp:'08/17/2018'},
  {activity:'Log In', login:'user@domain.com.ph', reason:'no reason', billNumber:1234567890, timeStamp:'08/17/2018'},
  {activity:'Log In', login:'user@domain.com.ph', reason:'no reason', billNumber:1234567890, timeStamp:'08/17/2018'},
  {activity:'Log In', login:'user@domain.com.ph', reason:'no reason', billNumber:1234567890, timeStamp:'08/17/2018'},
  {activity:'Log In', login:'user@domain.com.ph', reason:'no reason', billNumber:1234567890, timeStamp:'08/17/2018'},
  {activity:'Log In', login:'user@domain.com.ph', reason:'no reason', billNumber:1234567890, timeStamp:'08/17/2018'}
];

  columns: string[] = ['activity', 'login', 'reason', 'billNumber', 'timeStamp'];
  headers: string[] = ['Activity', 'Login', 'Reason', 'Bill Number', 'Time Stamp'];
  doPrint: boolean = false;

  constructor() { }

  ngOnInit() {
  }

}
