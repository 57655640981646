import { Component, OnInit, Input } from '@angular/core';

@Component({
  selector: 'app-billing-statement-additional-bill-information',
  templateUrl: './billing-statement-additional-bill-information.component.html',
  styleUrls: ['./billing-statement-additional-bill-information.component.scss']
})
export class BillingStatementAdditionalBillInformationComponent implements OnInit {

  @Input()
  rightAdditionalBillInformation = []

  constructor() { }

  ngOnInit() {
  }

}
