import { NgModule } from '@angular/core';
import { RouterModule, Routes } from '@angular/router';
import { AuthenticationGuard } from 'microsoft-adal-angular6';
import {NoopAnimationsModule} from '@angular/platform-browser/animations';
import {MatButtonModule, MatCheckboxModule, MatNativeDateModule, MatTableModule,MatPaginatorModule, MatDialogModule} from '@angular/material';
import {MatDatepickerModule} from '@angular/material/datepicker';
import { FormsModule } from '@angular/forms';

import { HomeComponent }   from './pages/home/home.component';
import { PrintViewComponent } from './pages/print-view/print-view.component';
import { PageTwoComponent } from './pages/bill/page-two/page-two/page-two.component';

const routes: Routes = [
  { path: '', redirectTo: '/home', pathMatch: 'full' },
  { path: 'home', component: HomeComponent, canActivate: [AuthenticationGuard]},
  // { path: 'home', component: HomeComponent },
  { path: 'printview', component: PrintViewComponent},
  { path: 'prev', component: PageTwoComponent}
  
];

@NgModule({
  imports: [ RouterModule.forRoot(routes), FormsModule, NoopAnimationsModule, MatPaginatorModule, MatTableModule, MatButtonModule, MatCheckboxModule, MatDatepickerModule, MatNativeDateModule, MatDialogModule ],
  exports: [ RouterModule, FormsModule, MatButtonModule, MatPaginatorModule, MatTableModule, MatCheckboxModule, MatDatepickerModule, MatNativeDateModule, MatDialogModule ]
})
export class AppRoutingModule { }
