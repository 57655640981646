import { Component, OnInit, Input } from '@angular/core';
import * as moment from 'moment';

@Component({
  selector: 'app-billing-statement-static-footer',
  templateUrl: './billing-statement-static-footer.component.html',
  styleUrls: ['./billing-statement-static-footer.component.scss']
})
export class BillingStatementStaticFooterComponent implements OnInit {
@Input()bill:any;
value = '';
  constructor() { }

  ngOnInit() {
  	this.value = this.bill.electricbillsummary.accountentry.bill_notes_list.service.sin + moment(this.bill.electricbillsummary.accountentry.bill_notes_list.service.bill_note_data.billnotedata.creationdate).format('MMDDYYYY');
  }

  get values(): string[] {
    return this.value.split('\n');
  }

}
