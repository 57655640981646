import { Component, OnInit, Input } from '@angular/core';

@Component({
  selector: 'app-billing-statement-total-bill',
  templateUrl: './billing-statement-total-bill.component.html',
  styleUrls: ['./billing-statement-total-bill.component.scss']
})
export class BillingStatementTotalBillComponent implements OnInit {

  @Input() rightTotalBillList = {};
  // @Input() thirdTotalBillList = {};

  constructor() { }

  ngOnInit() {
  }

}
