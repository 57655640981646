import { Component, AfterViewInit, Input, Output, EventEmitter } from '@angular/core';
import {SearchPipe} from '../../pipes/search-pipe';

@Component({
  selector: 'app-filter-criteria',
  templateUrl: './filter-criteria.component.html',
  styleUrls: ['./filter-criteria.component.scss']
})
export class FilterCriteriaComponent implements AfterViewInit {

  	@Output()
  	setFiltered = new EventEmitter<any>();

	@Input() criterias: any[];
	@Input() filter: any[];
	@Input() unfiltered: any[] = [];

	selectedCriteria: any;
	
	search: any = "";
  constructor() { }

  ngAfterViewInit() {
  }

  onChange(){
  	let searchPipe = new SearchPipe();
  	let fil = {};
  	
  	if(this.search != ""){
   		
   		fil[this.selectedCriteria.type] = this.search;
  	
  	
	  	if(this.selectedCriteria.type == "all")
	  	{
	  		fil = {};
	  		this.filter = [];
	  		this.criterias.forEach((obj)=>{
	  			if(obj.type != "all")
	  			{
	  				fil[obj.type] = this.search;

				 	this.filter = this.filter.concat(searchPipe.transform(this.unfiltered, fil, true));
	  				fil = {};
	  			}
	  		}); 	
	   	}else{
	   		this.filter = searchPipe.transform(this.unfiltered, fil, true);	
	   	}
		this.filter = this.removeDuplicates(this.filter, 'statement_num')
	   	this.setFiltered.emit(this.filter);
   	}

  }

  removeDuplicates(originalArray, prop) {
	let newArray = [];
	let lookupObject  = {};

	for(let i in originalArray) {
	   lookupObject[originalArray[i][prop]] = originalArray[i];
	}

	for(let i in lookupObject) {
		newArray.push(lookupObject[i]);
	}
	 return newArray;
}

}
