import { Component, OnInit, Input } from '@angular/core';

@Component({
  selector: 'app-billing-statement-reference-section',
  templateUrl: './billing-statement-reference-section.component.html',
  styleUrls: ['./billing-statement-reference-section.component.scss']
})
export class BillingStatementReferenceSectionComponent implements OnInit {

	@Input() bill:any;
  bcValue= 'asdsadasd21312edasda';
  value = '';
  rightValue = '';
  constructor() { }

  ngOnInit() {
    this.value = this.bill.electricbillsummary.accountentry.bill_notes_list.service.invoicesummary.phonereference;
    this.rightValue = this.bill.electricbillsummary.accountentry.bill_notes_list.service.invoicesummary.meralcoreference;
  }

  get values(): string[] {
    return this.value.split('\n');
  }

  get rightValues(): string[]{
    return this.rightValue.split('\n');
  }

}
